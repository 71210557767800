<template>
    <div class="pageData">
        <div style="margin-bottom: 60px">
            <div class="row">
                <div class="row" style="margin: unset;flex: 1">
                    <a-input style="width: 160px;margin-right: 20px" v-model="KeyWord" placeholder="请输入调用名称"/>
                    <a-button class="label" type="primary" @click="_dataSearch">查询</a-button>
                </div>
                <a-button class="label" type="primary" @click="_dataAction(true,'')">添加</a-button>
            </div>
            <a-table :columns="dataColumns" :data-source="dataList" :pagination="false">
                <span slot="Id" slot-scope="text, record">
<!--                     <i class="iconfont icon-yulan icon-bth" @click='_view(true,record)'></i>-->
                             <a-icon type="bars" class="iconfont icon-yulan icon-bth" @click='_view(true,record)' />
                    <a-icon type="setting"  class="icon-bth"  @click="_dataAction(true,record)" />
<!--                    <a-icon class="icon-bth"  @click="_dataAction(true,record)" type="form"/>-->
                     <a-popconfirm
                         title="你确定要删除嘛?"
                         ok-text="确定"
                         cancel-text="取消"
                         @confirm="dataDel(record.Id)"
                         @cancel="maxClassify = -1"
                     >

                        <a-icon class="icon-bth"  type="delete"/>
                      </a-popconfirm>

                </span>
            </a-table>
        </div>

        <div class="alert-footer">
            <a-button style="margin-right:25px" @click="visible()">关闭</a-button>
            <!--            <a-button type="primary" @click="_dataToAction">确定</a-button>-->
        </div>


        <!--        数据调用 操作start-->
        <a-drawer title="数据调用" width="800" :closable="false" :visible="dataStatus" @close="_dataAction(false)">
            <div>
                <div class="row">
                    <span class="label">调用名称</span>
                    <a-input style="flex: 1;" v-model="FieldTitle" placeholder="请输入调用名称"/>
                </div>
                <div class="row" v-for="(fieldItem,index) in ConditionList">
                    <span class="label">条件</span>
                    <a-select style="width: 120px;margin-right: 10px" @change="changeField($event,index)"
                              v-model="fieldItem.field" placeholder="请选择字段">
                        <a-select-option value="">请选择字段</a-select-option>
                        <a-select-option v-for="item in fieldList" :value="item.FieldName">{{ item.Title }}
                        </a-select-option>
                    </a-select>
                    <a-select style="width: 120px;margin-right: 10px" v-model="fieldItem.condition" placeholder="请选择条件">
                        <a-select-option value="">请选择条件</a-select-option>
                        <a-select-option v-for="item in dataCondition" :value="item.value">{{ item.name }}
                        </a-select-option>
                    </a-select>

                    <a-select v-if="fieldItem.fieldType=='select'" style="flex: 1;margin-right: 10px"
                              v-model="fieldItem.value" placeholder="请选择值">
                        <a-select-option value="">请选择值</a-select-option>
                        <a-select-option v-for="FieldValueItem in fieldItem.FieldValue" :value="FieldValueItem">
                            {{ FieldValueItem }}
                        </a-select-option>
                    </a-select>
                    <a-input v-else style="flex: 1;margin-right: 10px" v-model="fieldItem.value" placeholder="请输入值"/>

                    <a-icon v-show="index!=0" @click="_delCondition(index)" class="del-icon" type="close-circle"/>
                    <a-icon @click="_addCondition(index)" class="color-blue" type="plus-circle"/>


                </div>
                <div class="row">
                    <span class="label">返回字段</span>

                    <a-select style="flex:1;" placeholder="请选择字段" mode="multiple" v-model="ReturnFields">
                        <a-select-option value="">请选择</a-select-option>
                        <a-select-option v-for="item in fieldList" :value="item.FieldName">{{ item.Title }}
                        </a-select-option>
                    </a-select>
                </div>
                <div class="row">
                    <span class="label">排序字段</span>

                    <a-select style="flex:1;" placeholder="请选择排序字段" v-model="OrderField">
                        <!--                        <a-select-option value="">请选择</a-select-option>-->
                        <a-select-option value="CreateTime">创建时间</a-select-option>
                        <a-select-option v-for="item in fieldList" :value="item.FieldName">{{ item.Title }}
                        </a-select-option>
                    </a-select>
                </div>
                <div class="row">
                    <span class="label">排序方式</span>

                    <a-select style="flex:1;" placeholder="请选择排序方式" v-model="OrderType">
                        <!--                        <a-select-option value="">请选择</a-select-option>-->
                        <a-select-option value="1">顺序</a-select-option>
                        <a-select-option value="2">倒序</a-select-option>

                    </a-select>
                </div>
                <div class="row">
                    <span class="label">返回条数</span>
                    <a-input-number style="flex: 1;" v-model="FieldNum" placeholder="请输入返回条数" :min="1"/>
                </div>

            </div>
            <div class="alert-footer">
                <a-button style="margin-right:25px" @click="_dataAction(false)">取消</a-button>
                <a-button type="primary" @click="_dataToAction">确定</a-button>
            </div>
        </a-drawer>
        <!--        数据调用 操作end-->

        <a-drawer title="预览列表" width="1000" :closable="false" :visible="viewStatus" @close="_view(false,'')">
            <a-table  :data-source="viewList" :pagination="false">
                <a-table-column v-for="items in viewColumns"   :key="items.FieldName"
                                :title="items.Title" :data-index="items.FieldName">
                    <div slot-scope="text,record" v-viewer>
                        <!--富文本-->
                        <span style="display: inline-block;overflow: hidden"
                              v-if="items.FieldType=='textedit'" v-html="text"></span>
                        <!--人员-->
                        <span v-else-if="items.FieldType=='user'"
                              v-for="(userinfo,userinfoIndex) in record[items.FieldName]">{{ userinfo.title }}{{userinfoIndex != (record[items.FieldName].length-1)?'、':''}}</span>
                        <!--操作-->
                        <span v-else-if="items.FieldType=='action'">

                        <i class="iconfont icon-bianji icon-btn" style="font-size: 20px"
                           @click.stop='_action(text,record)'></i>&nbsp;&nbsp;
                    <a-popconfirm title="您确定要删除吗?" @confirm="_del(text,record)" okText="确定" cancelText="取消">
                        <i class="iconfont icon-shanchu- icon-btn"></i>
                    </a-popconfirm>
                    </span>
                        <!--复选框-->
                        <span v-else-if="items.FieldType=='checkbox'" v-for="(checkbox,checkboxIndex) in record[items.FieldName]">
                       {{ checkbox }}{{ checkboxIndex != (record[items.FieldName].length-1)?'、':'' }}
                    </span>
                        <!--图片-->
                        <img v-else-if="items.FieldType=='imgupload'" v-for="imgSrcItem in record[items.FieldName]"
                             :src="imgSrcItem.FileUrl" style="width: 50px;margin-right: 5px;margin-bottom: 5px;cursor: pointer;" alt="">
                        <!--文件-->
                        <span v-else-if="items.FieldType=='filesupload'" v-for="(filesuploadItem,filesuploadItemIndex) in record[items.FieldName]">
                        <a-popconfirm style="cursor: pointer;" title="你确定要下载嘛?"
                                      @confirm="_download(filesuploadItem.FileUrl,filesuploadItem.FileName)" okText="确定"
                                      cancelText="取消">
                           {{ filesuploadItem.FileName }}{{filesuploadItemIndex != (record[items.FieldName].length-1) ?'、':''}}
                        </a-popconfirm>
                    </span>
                        <!--文件柜-->
                        <span v-else-if="items.FieldType=='filesource'" v-for="(filesourceItem,filesourceItemIndex) in record[items.FieldName]">
                        <a-popconfirm style="cursor: pointer;" title="你确定要下载嘛?"
                                      @confirm="_download(filesourceItem.FileUrl,filesourceItem.FileName)" okText="确定"
                                      cancelText="取消">
                            {{ filesourceItem.FileName }}{{filesourceItemIndex != (record[items.FieldName].length-1)?'、':''}}
                        </a-popconfirm>
                    </span>
                        <span v-else-if="items.FieldType=='address'">

                       {{ text }}

                    </span>
<!--                        分类选择-->
                        <span v-else-if="items.FieldType=='category' && text" v-for="categoryItem in text">
                         {{ categoryItem }}
                        </span>
                        <!--其它-->
                        <span v-else>
                        {{ text }}
                    </span>
                    </div>

                </a-table-column>
            </a-table>

            <div class="alert-footer">
                <a-button style="margin-right:25px" @click="_view(false,'')">关闭</a-button>
                <!--            <a-button type="primary" @click="_dataToAction">确定</a-button>-->
            </div>
        </a-drawer>
    </div>
</template>

<script>
import Viewer from 'v-viewer';
import 'viewerjs/dist/viewer.css';
import Vue from 'vue'
Vue.use(Viewer);
export default {
    name: "pageData",
    data() {
        return {
            dataColumns: [
                {
                    title: '调用名称',
                    dataIndex: 'Title',
                    align: 'center',
                },
                {
                    title: '模块名称',
                    dataIndex: 'ModelName',
                    align: 'center',
                },
                {
                    title: '接口地址',
                    dataIndex: 'Url',
                    align: 'center',
                },
                {
                    title: '操作',
                    dataIndex: 'Id',
                    align: 'center',
                    scopedSlots: {customRender: 'Id'},
                },
            ],
            KeyWord: undefined,
            dataList: [],//调用名称列表

            dataCondition: [
                {"name": "等于", "value": "="},
                {"name": "不等于", "value": "neq"},
                {"name": "包含", "value": "like"},
                {"name": "大于", "value": ">"},
                {"name": "大于并等于", "value": ">="},
                {"name": "小于并", "value": "<="},
            ],
            dataStatus: false,
            fieldList: [],//模块字段列表
            ConditionList: [],//条件列表
            ReturnFields: [],//返回字段列表
            FieldNum: 1,
            OrderField: 'CreateTime',
            OrderType: '2',
            FieldTitle: undefined,
            dataId: '',

            viewColumns:[],
            viewList:[],
            viewStatus:false,
        }
    },
    props: {
        FieldModelId: {
            type: String,
        },
        FieldModelName: {
            type: String,
        }
    },
    created() {
        this._dataList();

    },

    methods: {
        _download(url, name) {
            let downUrl = url + '?attname=' + name;
            let a = document.createElement('a')
            let event = new MouseEvent('click')
            a.download = name;
            a.href = downUrl
            a.dispatchEvent(event)
            //window.open(downUrl);

        },
        _view(val,data){
            this.viewStatus = val;
            if(val){
                let self = this;
                self.viewColumns =[];
                this.$message.loading('加载中...',0);
                this.$axios.get(129466,{
                   Id:data.Id
                },res=>{
                    this.$message.destroy();
                    if(res.data.code==1){
                        self.viewColumns = res.data.column;
                        console.log(self.viewColumns)
                        self.viewList =res.data.data;

                    }else{
                        self.viewList = [];

                        this.$message.error(res.data.msg);
                    }
                })
            }else{
                this.viewList = [];
                this.viewColumns = [];
            }
        },
        //数据调用列表start

        _dataSearch() {
            this._dataList();
        },
        visible() {
            this.dataList = [];
            
            this.$emit('visible', false);
        },
        _dataList() {
            let self = this;
            this.$message.loading('加载中...', 0);
            this.$axios.get(129464, {
                ModelId: self.FieldModelId,
                Page: 1,
                PageSize: 100,
                KeyWord: self.KeyWord,
            }, res => {
                this.$message.destroy();
                if (res.data.code == 1) {
                    self.dataList = res.data.data;
                } else {
                    self.dataList = [];
                    this.$message.error(res.data.msg)
                }

            })
        },
        dataDel(val) {
            this.$message.loading('操作中..', 0);
            let self = this;
            this.$axios.get(129465, {
                Id: val
            }, res => {
                this.$message.destroy();
                if (res.data.code == 1) {
                    self._dataList();
                    this.$message.success(res.data.msg);
                } else {
                    this.$message.error(res.data.msg);
                }
            })
        },
        //数据调用列表end
        //数据操作start
        _dataAction(val, data) {
            this.dataStatus = val;
            let self = this;
            if (val) {
                if (data) {
                    if (data.hasOwnProperty('FieldList')) {
                        this.ReturnFields = JSON.parse(data.FieldList)
                    } else {
                        this.ReturnFields = [];
                    }
                    this.ConditionList = JSON.parse(data.Condition);
                    this.FieldNum = data.Limit;
                    this.OrderType = data.OrderType + '';
                    this.OrderField = data.OrderField;
                    this.FieldTitle = data.Title;
                    this.dataId = data.Id;
                }else{
                    let obj = {
                        "field": "",
                        "condition": "",
                        "value": "",
                        "fieldType": "",
                        "FieldValue": "",
                        "Title": "",
                    }
                    this.ConditionList.push(obj);
                }
                if(this.fieldList.length==0){
                    this.dataModelChange(this.FieldModelId);
                }

            } else {
                this.fieldList = [];//字段列表
                this.ConditionList = []; //条件列表
                this.ReturnFields = [];//返回字段
                this.OrderField = 'CreateTime';
                this.OrderType = '2';
                this.FieldNum = 1;
                this.dataId = "";
                this.FieldTitle = undefined;
            }
        },
        dataModelChange(val) {
            let self = this;
            this.$axios.post(5836165, {ModelId: val}, res => {
                if (res.data.code === 1) {
                    self.fieldList = res.data.data;
                } else {
                    self.fieldList = [];
                    this.$message.error('该模块下暂无数据');
                }
            });
        },
        changeField(val, index) {
            let FieldType = '';
            let FieldValue = [];
            let Title = "";
            this.ConditionList[index].fieldType = '';
            this.ConditionList[index].FieldValue = '';
            this.ConditionList[index].value = '';
            this.ConditionList[index].Title = '';

            for (let i = 0; i < this.fieldList.length; i++) {
                if (this.fieldList[i].FieldName == val) {
                    FieldType = this.fieldList[i].FieldType;
                    FieldValue = this.fieldList[i].FieldValue;
                    Title = this.fieldList[i].Title;
                }
            }
            this.ConditionList[index].fieldType = FieldType;
            this.ConditionList[index].FieldValue = FieldValue;
            this.ConditionList[index].Title = Title;

        },
        _addCondition(index) {
            let obj = {
                "field": "",
                "condition": "",
                "value": "",
                "fieldType": "",
                "FieldValue": "",
                "Title": "",
            }
            this.ConditionList.push(obj);
        },
        _delCondition(index) {
            this.ConditionList.splice(index, 1);
        },
        _dataToAction() {
            let self = this;
            let FieldTitle = this.FieldTitle || '';
            if (!FieldTitle) {
                this.$message.error('请先输入调用名称');
                return false;
            }
            if (!self.FieldModelId) {
                this.$message.error('请先选择模块');
                return false;
            }
            if (self.ConditionList.length == 0) {
                this.$message.error('请选择条件');
                return false;

            }

            for (let i = 0; i < self.ConditionList.length; i++) {
                if (!self.ConditionList[i].condition) {
                    this.$message.error('请输入字段"' + self.ConditionList[i].Title + '"的条件');
                    return false;
                    break;
                }
                if (!self.ConditionList[i].value) {
                    this.$message.error('请选择字段"' + self.ConditionList[i].Title + '"的值');
                    return false;
                    break;
                }
            }

            this.$message.loading('操作中', 0);
            this.$axios.post(129463, {
                Condition: JSON.stringify(self.ConditionList),
                FieldList: JSON.stringify(self.ReturnFields),
                Limit: self.FieldNum,
                ModelId: self.FieldModelId || '',
                OrderField: self.OrderField || '',
                OrderType: self.OrderType || '',
                Title: self.FieldTitle || '',
                ModelName: self.FieldModelName,
                Id: self.dataId,
            }, res => {
                this.$message.destroy();
                if (res.data.code == 1) {
                    this.$message.success(res.data.msg);
                    self._dataList();
                    self._dataAction(false);
                } else {
                    this.$message.error(res.data.msg);
                }
            })
        },
        //数据调用操作end
    }
}
</script>

<style scoped lang="less">
.row {
    margin-bottom: 20px;
    display: flex;
    align-items: center;

    .label {
        display: inline-block;
        width: 90px;
        white-space: nowrap;

        span {
            font-size: 16px;
            color: #FF6600
        }
    }
}

.del-icon {
    color: red;
    font-size: 18px;
    margin-right: 8px;
    cursor: pointer;
}

.color-blue {
    color: rgb(24, 144, 255) !important;
    font-size: 18px;
    cursor: pointer;
}

.alert-footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e9e9e9;
    padding: 10px 16px;
    background: #fff;

    button {
        width: 120px;
    }
}

.icon-bth{
    font-size: 20px;
    margin-right: 8px;
    color: #605d5da6;
}
</style>