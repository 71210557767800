<template>
    <div class="pageAdmin">
        <div class="header flex">
            <div>
                <div class="condition">
                    <span>模块名称</span>
                    <a-input placeholder="请输入" style="width: 150px;" v-model="searchName"/>
                </div>
                <a-button class="search-style" @click="_clear" style="margin-right: 10px">重置</a-button>
                <a-button type="primary" @click="_search">查询</a-button>
            </div>
            <div>
                <a-button type="primary" @click="_setItem('add')">新建模块</a-button>
            </div>
        </div>

        <div class="container">
            <a-table bordered :columns="tableColumns" :dataSource="tableData" :pagination="false">
                <div slot="sNumber"  slot-scope="text, record, index">
                    {{index+1}}
                </div>
                <div slot="operation"  slot-scope="text, record, index" style="width: 200px">
                    <!--<a-popconfirm class="icon-btn" style="cursor: pointer;font-size: 20px" title="你确定要复制链接嘛?" @confirm="_copy(index)" okText="确定" cancelText="取消">-->
                        <!--<a-icon type="copy"  />-->
                    <!--</a-popconfirm>-->
                    <a-icon class="icon-btn"  @click="_dataListAction(true,record)" type="api" />

                    <a-icon type="bars" @click="_pageList(index)" class="icon-btn" style="font-size: 20px;cursor: pointer"/>
                    <i class="iconfont icon-bianji icon-btn" style="font-size: 20px" @click.stop='_setItem(index)'></i>
                    
                    <a-popconfirm title="您确定要删除吗?" @confirm="_delTableRow(index)" okText="确定" cancelText="取消">
                        <i class="iconfont icon-shanchu- icon-btn"></i>
                    </a-popconfirm>
<!--                    <a-icon type="setting"  class="icon-bth"  @click="_dataAction(true,record)" />-->
                    <a-icon type="setting" @click="_fieldOnshow(index)" style="font-size: 16px;cursor: pointer"/>
                </div>
            </a-table>
            <div style="margin: 20px 0;" v-if="pageTotal>10">
                <a-pagination style="margin-top: 35px;text-align: right;"  :pageSize="PageSize" :total="pageTotal" @change="_PageChange" />
            </div>
        </div>

        <a-drawer :title="disabled?'修改模块':'新建模块'" placement="right" width="500" :closable="false" :visible="visible" @close="onClose">
            <div style="padding-bottom: 55px;display: flex;height: 100%">
                <div class="container" style="margin-top: 0;width: 100%">
                    <p class="row flex" style="justify-content: end">
                        <span class="label">模块名称</span>
                        <a-input style="flex:1;" v-model="templateName" placeholder="请输入" />
                    </p>
                    <p class="row flex" style="justify-content: end">
                        <span class="label">模块标识</span>
                        <a-input style="flex:1;" :disabled="disabled" v-model="templateId" placeholder="请输入" />
                    </p>
                    <p class="row flex" v-if="setItem ==-1" style="justify-content: end">
                        <span class="label">选择模块</span>
                        <a-select style="flex:1;" @change="modelChange"  placeholder="请选择模块">
                            <a-select-option value="">请选择</a-select-option>
                            <a-select-option v-for="item in modelList" :value="item.Id">
                                {{item.Name}}
                            </a-select-option>
                           
                        </a-select>
                    </p>
                    <p class="row flex"  style="justify-content: end">
                        <span class="label">选择模板</span>
                        <!-- labelInValue-->
                        <a-select style="flex:1;" :value="tableModeId?{key:tableModeId,value:tableModeName}:''"  @change="tablemodelChange" labelInValue placeholder="请选择模板">
                            <a-select-option value="">请选择</a-select-option>
                            <a-select-option v-for="item in tableModeList" :value="item.Id">{{item.Title}}</a-select-option>

                        </a-select>
                    </p>
                    <p class="row flex" style="justify-content: end">
                        <span class="label"></span>
                        <a-button type="primary" @click="_copy">复制链接</a-button>
                    </p>

                </div>
                <div class="alert-footer">
                    <a-button style="margin-right:25px" @click="onClose">取消</a-button>
                    <a-button type="primary" @click="_addTemplate">确定</a-button>
                </div>
            </div>
        </a-drawer>

        <a-drawer placement="right" width="1200" :closable="false" :visible="fieldVisible" @close="fieldOnClose">
            <div style="padding-bottom: 55px;">
                <div>

                    <a-tabs default-active-key="1" @change="callback">
                        <a-tab-pane key="1" tab="字段信息">
                            <div style="text-align: right;margin-bottom: 15px;">
                                <a-button type="primary" @click="fieldChildrenDrawer = true">新增字段</a-button>
                            </div>
                            <a-table bordered :columns="fieldTableColumns" :dataSource="fieldTableData" :pagination="false">
                                <div slot="sNumber"  slot-scope="text, record, index">
                                    {{index+1}}
                                </div>
                                <div slot="FieldType"  slot-scope="text, record, index">
                            <span v-for="item in radioList">
                                {{item.value==text?item.label:''}}
                            </span>
                                </div>
                                <div slot="IsSystem"  slot-scope="text, record, index">
                                    {{text===1?'系统':'自定义'}}
                                </div>
                                <div slot="IsRequire"  slot-scope="text, record, index">
                                    {{text===1?'是':'否'}}
                                </div>


                                <div slot="operation" slot-scope="text, record, index">
                                    <i class="iconfont icon-bianji icon-btn" style="font-size: 16px;margin-right: 4px;" @click.stop='_setfieldChildren(index)'></i>
                                    <a-popconfirm title="您确定要删除吗?" @confirm="_delfieldTableData(index)" okText="确定" cancelText="取消">
                                        <i class="iconfont icon-shanchu- icon-btn"></i>
                                    </a-popconfirm>
                                </div>
                            </a-table>
                        </a-tab-pane>
                        <a-tab-pane key="2" tab="统计设置" force-render>
                            <div style="text-align: right;margin-bottom: 15px;">
                                <a-button type="primary" @click="fieldChildrenDrawerCount = true">新增统计字段</a-button>
                            </div>
                            <a-table bordered :columns="fieldTableColumnsCount" :dataSource="fieldTableDataCount" :pagination="false">
                                <div slot="operation" slot-scope="text, record, index">
                                    <i class="iconfont icon-bianji icon-btn" style="font-size: 16px;margin-right: 4px;" @click.stop='_setfieldChildrenCount(index)'></i>
                                    <a-popconfirm title="您确定要删除吗?" @confirm="_delfieldTableDataCount(index)" okText="确定" cancelText="取消">
                                        <i class="iconfont icon-shanchu- icon-btn"></i>
                                    </a-popconfirm>
                                </div>
                            </a-table>
                        </a-tab-pane>
                    </a-tabs>

                </div>
            </div>

            <a-drawer :title="FieldId?'修改字段':'新增字段'" width="1100" :closable="false" :visible="fieldChildrenDrawer" @close="fieldChildrenDrawerClose">
                <div style="padding-bottom: 55px;">
                    <div class="container" style="margin-top: 0">
                        <div class="row">
                            <span class="label"><span>*</span> 字段标题</span>
                            <a-input style="width: 350px;margin-left: 15px;" placeholder="请输入" v-model="fieldTitle"/>
                        </div>

                        <div class="row">
                            <span class="label"><span>*</span> 字段名称</span>
                            <a-input :disabled="disabled" style="width: 350px;margin-left: 15px;" placeholder="只允许字母加下划线组合" v-model="fieldValue" />
                        </div>
                        <div class="row " >
                            <span class="label"><span>*</span> 数据类型</span>
                            <div style="margin-left: 15px;width:600px;">
                                <a-radio-group name="radioGroup"  v-model="fieldType" @change="onChangeFieldType">
                                    <a-radio :value="item.value" style="margin-bottom: 10px;width:110px;" v-for="item in radioList">{{item.label}}</a-radio>
                                </a-radio-group>
                            </div>
                        </div>
                        <!--组织结构-->
                        <div class="row "  v-if="fieldType=='user'">
                            <span class="label"><span></span> 组织架构选项</span>
                            <div style="margin-left: 15px;width:600px;">
                                <a-radio-group name="radioGroup"  v-model="UserType" >
                                    <a-radio :value="0" style="margin-bottom: 10px;width:110px;" >只选人</a-radio>
                                    <a-radio :value="1" style="margin-bottom: 10px;width:140px;" >可选择人与部门</a-radio>
                                    <a-radio :value="2" style="margin-bottom: 10px;width:110px;" >只选部门</a-radio>
                                    <a-radio :value="4" style="margin-bottom: 10px;width:110px;" >只选中心</a-radio>
                                </a-radio-group>
                            </div>
                        </div>
                        <!--分类-->
                        <div class="row " v-if="fieldType=='category'">
                            <span class="label"><span></span>分类选项</span>
                            <div style="margin-left: 15px;width:600px;" v-if="categoryTreeData">
                                <a-tree-select
                                        @change="changeTree"
                                        style="width: 100%"
                                        :tree-data="categoryTreeData"
                                        v-model="defaultTreeId"
                                        tree-checkable
                                        :show-checked-strategy="SHOW_ALL"
                                        placeholder="请选择分类选项"
                                />
                                
                            </div>
                        </div>
                        <!--文件源-->
                        <div class="row "  v-if="fieldType=='filesource'">
                            <span class="label"><span></span>文件源选项</span>
                            <div style="margin-left: 15px;width:600px;" v-if="fileTreeData">
                                <a-tree-select
                                        @change="changeTree"
                                        v-model="defaultTreeId"
                                        style="width: 100%"
                                        :tree-data="fileTreeData"
                                        tree-checkable
                                        :show-checked-strategy="SHOW_ALL"
                                        placeholder="请选择文件源选项"
                                />
                            </div>
                        </div>

                        <div class="row "  v-if="fieldType=='user' || fieldType=='category' || fieldType=='filesource'">
                            <span class="label"><span></span> 数量选择</span>
                            <div style="margin-left: 15px;width:600px;">
                                <a-radio-group name="radioGroup" v-model="UserNum" >
                                    <a-radio :value="0" style="margin-bottom: 10px;width:110px;" >单选</a-radio>
                                    <a-radio :value="1" style="margin-bottom: 10px;width:140px;" >多选</a-radio>
                                </a-radio-group>
                            </div>
                        </div>
                        <div class="row " >
                            <span class="label"><span>*</span> 列表显示</span>
                            <div style="margin-left: 15px;">
                                <a-radio-group name="radioGroup"  v-model="ListStatus">
                                    <a-radio :value="1">是</a-radio>
                                    <a-radio :value="0">否</a-radio>
                                </a-radio-group>
                            </div>
                        </div>

                        <div class="row " >
                            <span class="label"><span>*</span> 是否用于搜索</span>
                            <div style="margin-left: 15px;">
                                <a-radio-group name="radioGroup" v-model="isSearch">
                                    <a-radio :value="1">是</a-radio>
                                    <a-radio :value="0">否</a-radio>
                                </a-radio-group>
                            </div>
                        </div>
                        <div class="row " >
                            <span class="label"><span>*</span> 是否必填</span>
                            <div style="margin-left: 15px;">
                                <a-radio-group name="radioGroup" v-model="IsRequire">
                                    <a-radio :value="1">是</a-radio>
                                    <a-radio :value="0">否</a-radio>
                                </a-radio-group>
                            </div>
                        </div>
                        <div class="row " >
                            <span class="label"><span>*</span> 值是否唯一</span>
                            <div style="margin-left: 15px;">
                                <a-radio-group name="radioGroup" v-model="Only">
                                    <a-radio :value="1">是</a-radio>
                                    <a-radio :value="0">否</a-radio>
                                </a-radio-group>
                            </div>
                        </div>


                        <div class="row">
                            <span class="label" style="vertical-align: top">默认值</span>
                            <div style="width: 350px;margin-left: 15px;height: 100px;resize: none;display: inline">
                                <a-tag style="margin-bottom: 5px" v-if="defaultValue.length>0" v-for="(item,index) in defaultValue" closable  :afterClose="() => handleClose(item)"  >
                                    {{ item }}
                                </a-tag>

                                <a-input
                                        v-if="inputVisible"
                                        ref="input"
                                        type="text"
                                        size="small"
                                        :style="{ width: '78px' }"
                                        :value="inputValue"
                                        @change="handleInputChange"
                                        @blur="handleInputConfirm"
                                        @keyup.enter="handleInputConfirm"
                                />
                                <a-tag v-else style="background: #fff; borderStyle: dashed;" @click="showInput">
                                    <a-icon type="plus" /> 自定义
                                </a-tag>
                                <span style="font-size: 12px;color:red">如果定义字段类型为下拉框、单选项、多选项时，此处必填</span>
                            </div>

                            
                        </div>

                        <div class="row">
                            <span class="label">提示文字</span>
                            <a-input style="width: 350px;margin-left: 15px;" v-model="hintText" placeholder="不填的话默认：请输入" />
                        </div>
                        <div class="row">
                            <span class="label">排序</span>
                            <a-input-number :min="1" style="width: 350px;margin-left: 15px;" v-model="Sort" placeholder="不填的话默认：0"  />
                        </div>

                        <div class="row " >
                            <span class="label">菜单栏目</span>
                            <div style="margin-left: 15px; width: 350px">
                                <a-tree-select style="width: 100%" :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }" placeholder="请选择">
                                    <a-tree-select-node key="0-1" value="parent 1" title="parent 1">
                                        <a-tree-select-node key="0-1-1" value="parent 1-0" title="parent 1-0">
                                            <a-tree-select-node key="random" :selectable="false" value="leaf1" title="my leaf" />
                                            <a-tree-select-node key="random1" value="leaf2" title="your leaf" />
                                        </a-tree-select-node>
                                        <a-tree-select-node key="random2" value="parent 1-1" title="parent 1-1">
                                            <a-tree-select-node key="random3" value="sss">
                                                <b slot="title" style="color: #08c">sss</b>
                                            </a-tree-select-node>
                                        </a-tree-select-node>
                                    </a-tree-select-node>
                                </a-tree-select>
                            </div>
                        </div>
                         <div class="row " >
                            <span class="label"><span>*</span> 隐藏字段</span>
                            <div style="margin-left: 15px;">
                                <a-radio-group name="radioGroup" v-model="IsHide">
                                    <a-radio :value="1">是</a-radio>
                                    <a-radio :value="0">否</a-radio>
                                </a-radio-group>
                            </div>
                          </div>
                          <div class="row " >
                            <span class="label"><span>*</span> 是否只读</span>
                            <div style="margin-left: 15px;">
                                <a-radio-group name="radioGroup" v-model="IsDisabled">
                                    <a-radio :value="1">是</a-radio>
                                    <a-radio :value="0">否</a-radio>
                                </a-radio-group>
                            </div>
                          </div>
                           
                    </div>
                    <div class="alert-footer">
                        <a-button style="margin-right:25px" @click="fieldChildrenDrawerClose">取消</a-button>
                        <a-button type="primary" @click="_setField">确定</a-button>
                    </div>
                </div>
            </a-drawer>
            <a-drawer :title="FieldId?'修改字段':'新增字段'" width="1100" :closable="false" :visible="fieldChildrenDrawerCount" @close="fieldChildrenDrawerCountClose">
                <div style="padding-bottom: 55px;">
                    <div class="container" style="margin-top: 0">
                        <div class="row">
                            <span class="label"><span>*</span> 统计名称</span>
                            <a-input style="width: 350px;margin-left: 15px;" placeholder="请输入" v-model="fieldTitle"/>
                        </div>

                        <div class="row">
                            <span class="label"><span>*</span> 统计字段</span>
                            <a-input :disabled="disabled" style="width: 350px;margin-left: 15px;" placeholder="只允许字母加下划线组合" v-model="fieldName" />
                        </div>
                        <div class="row">
                            <span class="label"><span>*</span> 统计方式</span>
                            <a-select default-value="count" style="width: 120px;margin-left: 15px;" @change="handleChange">
                                <a-select-option :value="item.value"  v-for="item in statistics">
                                    {{item.name}}
                                </a-select-option>
                            </a-select>
                        </div>
                    </div>
                    <div class="alert-footer">
                        <a-button style="margin-right:25px" @click="fieldChildrenDrawerCountClose">取消</a-button>
                        <a-button type="primary" @click="_setFieldCount">确定</a-button>
                    </div>
                </div>
            </a-drawer>
        </a-drawer>

<!--数据调用列表star-->
        <a-drawer v-if="dataListSatus" title="数据调用列表" width="1200" :closable="false" :visible="dataListSatus" @close="_dataListAction(false)">
            <pageData :FieldModelName="FieldModelName" :FieldModelId="FieldModelId" @visible="_dataListAction(false)"></pageData>
        </a-drawer>
<!-- 数据调用列表end-->


        <div v-show="spinning"  style="position:fixed;left:50%;top: 50%;transform: translate(-50%,-50%);">
            <a-spin style="margin: 0px 0%;">
                <a-icon slot="indicator" type="loading" :spinning="spinning" style="font-size: 44px;color:red;" spin/>
            </a-spin>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue'
    var user = JSON.parse(window.sessionStorage.getItem("userInfo"));
    import VueClipboard from 'vue-clipboard2';//复制
    import pageData from 'pages/PageAdmin/pageData';
    Vue.use(VueClipboard)
    export default {
        name: "pageAdmin",
        components:{pageData},
        data(){
            return{
                statistics:[
                    {name:'计数',value:'count'},
                    {name:'相加',value:'plus'},
                    {name:'去重计数',value:'unique'},
                ],
                userinfo:JSON.parse(sessionStorage.getItem("userInfo")),
                spinning:false,
                PageSize:10,
                Page:'',
                searchName: '',
                searchId: '',
                modelList:'',
                CopyModelId:'',
                tableColumns: [
                    {
                        title: '序号',
                        dataIndex: 'key',
                        width: 70,
                        align: 'center',
                        scopedSlots: { customRender: 'sNumber' },
                    },
                    {
                        title: '模块名称',
                        dataIndex: 'Name',
                        align: 'center',
                    },
                    {
                        title: '模块标识',
                        dataIndex: 'Label',
                        align: 'center',
                        width: 150,
                    },
                    {
                        title: '操作',
                        dataIndex: 'operation',
                        width: 160,
                        align: 'center',
                        scopedSlots: { customRender: 'operation' },
                    }
                ],
                tableData: [],
                visible: false,
                setItem: -1,
                templateName: '',
                templateId: '',
                fieldVisible: false,
                fieldTableColumns: [
                    {
                        title: '序号',
                        dataIndex: 'key',
                        width: 70,
                        align: 'center',
                        scopedSlots: { customRender: 'sNumber' },
                    },
                    {
                        title: '标题',
                        dataIndex: 'Title',
                        align: 'center'
                    },
                    {
                        title: '字段名称',
                        dataIndex: 'FieldName',
                        align: 'center'
                    },
                    {
                        title: '字段类型',
                        dataIndex: 'FieldType',
                        align: 'center',
                        scopedSlots: { customRender: 'FieldType' },
                    },
                    {
                        title: '菜单分类',
                        dataIndex: 'IsSystem',
                        align: 'center',
                        scopedSlots: { customRender: 'IsSystem' },

                    },
                    {
                        title: '提示文字',
                        dataIndex: 'hintText',
                        align: 'center'
                    },
                    {
                        title: '是否必填',
                        dataIndex: 'IsRequire',
                        align: 'center',
                        scopedSlots: { customRender: 'IsRequire' },
                    },
                    {
                        title: '排序',
                        dataIndex: 'Sort',
                        align: 'center'
                    },
                    {
                        title: '操作',
                        dataIndex: 'operation',
                        width: 120,
                        align: 'center',
                        scopedSlots: { customRender: 'operation' },
                    }
                ],
                fieldTableData: [],
                fieldTableColumnsCount: [
                    {
                        title: '序号',
                        dataIndex: 'key',
                        width: 70,
                        align: 'center',
                        scopedSlots: { customRender: 'sNumber' },
                    },
                    {
                        title: '统计名称',
                        dataIndex: 'Title',
                        align: 'center'
                    },
                    {
                        title: '统计字段',
                        dataIndex: 'FieldName',
                        align: 'center'
                    },
                    {
                        title: '统计方式',
                        dataIndex: 'CountType',
                        align: 'center',
                        scopedSlots: { customRender: 'CountType' },
                    },
                    {
                        title: '操作',
                        dataIndex: 'operation',
                        width: 120,
                        align: 'center',
                        scopedSlots: { customRender: 'operation' },
                    }
                ],
                fieldTableDataCount: [],
                fieldChildrenDrawer: false,
                fieldChildrenDrawerCount: false,
                treeData: [
                    {
                        title: 'Node1',
                        value: '0-0',
                        key: '0-0',
                        children: [
                            {
                                value: '0-0-1',
                                key: '0-0-1',
                                scopedSlots: {
                                    // custom title
                                    title: 'title',
                                },
                            },
                            {
                                title: 'Child Node2',
                                value: '0-0-2',
                                key: '0-0-2',
                            },
                        ],
                    },
                    {
                        title: 'Node2',
                        value: '0-1',
                        key: '0-1',
                    },
                ],
                classifyData: '',
                radioList: [
                    {
                        label: '普通输入框',
                        value: 'textinput'
                    },
                    {
                        label: '单选框',
                        value: 'radio'
                    },
                    {
                        label: '多选框',
                        value: 'checkbox'
                    },
                    {
                        label: '多行文本',
                        value: 'textarea'
                    },
                    {
                        label: '富文本',
                        value: 'textedit'
                    },
                    {
                        label: '日期',
                        value: 'date'
                    },
                    {
                        label: '日期范围',
                        value: 'daterange'
                    },
                    {
                        label: '时间',
                        value: 'time'
                    },
                    {
                        label: '下拉选择框',
                        value: 'select'
                    },
                    {
                        label: '组织架构',
                        value: 'user'
                    },
                    {
                        label: '文件上传',
                        value: 'filesupload'
                    },
                    {
                        label: '图片上传',
                        value: 'imgupload'
                    },
                    {
                        label: '分类选择',
                        value: 'category'
                    },
                    {
                        label: '文件源',
                        value: 'filesource'
                    },
                    {
                        label: '地址选择',
                        value: 'address'
                    },
                    {
                        label: '学生选择',
                        value: 'student'
                    },


                ],
                pageTotal: 0,
                disabled:false,
                fieldTitle: '',
                fieldValue: '',
                fieldType: 1,
                defaultValue: [],
                hintText: '',
                isSearch: 0,
                fieldOnshowIndex: -1,
                Sort:0,
                IsRequire:1,
                IsHide:0,
                IsDisabled:0,
                Only:0,
                FieldId:'',
                inputVisible: false,
                inputValue: '',
                UserType:1,
                UserNum:1,
                ListStatus:1,
                categoryTreeData:'',
                fileTreeData:'',
                defaultTreeId:[],
                SHOW_ALL:'SHOW_ALL',
                defaultTreeName:[],
                tableModeList:'',
                tableModeName:'',
                tableModeId:'',
                dataListSatus: false,
                FieldModelName:'',
                FieldModelId:'',
                CountType:'count',
                fieldName:'',

            }
        },
        created(){

            this._loadData();
            this.$axios.get(1213, {

            },res => {
                if(res.data.code===1){
                    this.classifyData = res.data.data;
                }
            });
        },
        methods:{
            handleChange(value){
                console.log(value)
                this.CountType = value;
            },
            _dataListAction(val,data){

                this.dataListSatus = val;
                let list = data  || '';
                if(list){
                    this.FieldModelId = list.Id;
                    this.FieldModelName = list.Name;
                }

            },
            _loadData(){
                this.spinning = true;
                let self = this;
                this.$axios.get(5836164, {
                    Keyword:self.searchName,
                    PageSize:self.PageSize,
                    Page:self.Page,
                },res => {
                    self.spinning = false;
                    if(res.data.code===1){
                        self.tableData = res.data.data;
                        self.pageTotal = res.data.count;
                    }else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            _clear(){
                this.searchName = '';
                this._loadData();
            },
            _search(){
                this._loadData();
            },
            _PageChange(value){
                this.Page = value;
                this._loadData();
            },
            modelChange(value){
                this.CopyModelId = value;
            },
            tablemodelChange(value){
                this.tableModeName = value.label;
                this.tableModeId   = value.key;
            },
            _addTemplate(){
                this.spinning = true;
                let data = {
                    Id: this.setItem != -1 ? this.tableData[this.setItem].Id: '',
                    UserId: user.uid,
                    Name: this.templateName,
                    Label: this.templateId,
                    TableName: this.templateId,
                    ModelId:this.CopyModelId,
                    IndexTemplate: '',
                    ListTemplate: '',
                    DetailTemplate: '',
                    CatetoryDataLabel: '',
                    tableModeName:this.tableModeName,
                    tableModeId:this.tableModeId,
                };
               
                this.$axios.post(5836163,data,res => {
                    this.spinning = false;
                    if(res.data.code===1){
                        if(this.setItem == -1){
                            this.tableData.push({Name: this.templateName,Label: this.templateId,Id: res.data.data})
                        }else {
                            this.tableData[this.setItem].Name = this.templateName;
                            this.tableData[this.setItem].Label = this.templateId;
                        }
                        this.$message.success(res.data.msg);
                        this.onClose();
                    }else{
                        this.$message.error(res.data.msg);
                    }
                });
            },
            _setfieldChildrenCount(i){
                console.log(this.fieldTableDataCount[i])
                let data = this.fieldTableDataCount[i];
                this.fieldChildrenDrawerCount = true;
                this.fieldTitle = data.Title;
                this.fieldName = data.FieldName;
                this.CountType = data.CountType;
                this.disabled = true;//字段名称不允许修改
            },
            _setfieldChildren(i){
                let data = this.fieldTableData[i];
                this.fieldChildrenDrawer = true;
                this.fieldTitle = data.Title;
                this.UserNum = parseInt(data.UserNum);
                this.fieldType = data.FieldType;
                this.onChangeFieldType();
                this.fieldValue = data.FieldName;
                this.isSearch = parseInt(data.IsSearch);
                this.defaultValue =  data.FieldValue;
                this.IsRequire = parseInt(data.IsRequire);
                this.IsHide = parseInt(data.IsHide);
                this.IsDisabled = parseInt(data.IsDisabled);
                this.Only = parseInt(data.Only);
                this.Sort = data.Sort;
                this.hintText = data.hintText;
                this.FieldId = data.Id;
                this.ListStatus = parseInt(data.ListStatus);
                this.defaultTreeId = data.defaultTreeId;
                if(data.defaultTreeId){
                    this.defaultTreeId = JSON.parse(data.defaultTreeId);
                    this.defaultTreeName = JSON.parse(data.defaultTreeName);
                }else{
                    this.defaultTreeId =[] ;
                    this.defaultTreeName =[];
                }
                this.disabled = true;//字段名称不允许修改


            },
            _clearFieldTableDataCount(){
                this.fieldTitle = '';
                this.fieldValue = '';
                this.fieldType = '';
                this.defaultValue = [];
                this.hintText = '';
                this.isSearch = 0;
                this.IsHide = 0;
                this.IsRequire = 1;
                this.IsDisabled = 0;
                this.Sort = 1;
                this.FieldId = '';
                this.defaultTreeId=[];
                this.defaultTreeName =[];
                this.UserNum = 1;
                this.ListStatus = 1;
                this.disabled = false;

            },
            _clearFieldTableData(){
                this.fieldTitle = '';
                this.fieldValue = '';
                this.fieldType = '';
                this.defaultValue = [];
                this.hintText = '';
                this.isSearch = 0;
                this.IsHide = 0;
                this.IsRequire = 1;
                this.IsDisabled = 0;
                this.Sort = 1;
                this.FieldId = '';
                this.defaultTreeId=[];
                this.defaultTreeName =[];
                this.UserNum = 1;
                this.ListStatus = 1;
                this.disabled = false;
                
            },
            _delfieldTableData(i){
                this.spinning = true;
                let self = this;
                this.$axios.post(1095,{
                    Id:self.fieldTableData[i].Id,
                    UserId:self.userinfo.uid,
                },res=>{
                    self.spinning = false;
                    if(res.data.code==1){
                        self.fieldTableData.splice(i,1);
                        self.$message.success(res.data.msg);
                    }else{
                        this.$message.error(res.data.msg);
                    }
                })

            },
            _delfieldTableDataCount(i){
                this.spinning = true;
                let self = this;
                this.$axios.post(1095,{
                    ModelId:this.tableData[this.fieldOnshowIndex].Id,
                    FieldName:self.fieldTableDataCount[i].FieldName,
                    CountType:self.fieldTableDataCount[i].CountType,
                    UserId:self.userinfo.uid,
                },res=>{
                    self.spinning = false;
                    if(res.data.code==1){
                        self.fieldTableDataCount.splice(i,1);
                        self.$message.success(res.data.msg);
                    }else{
                        this.$message.error(res.data.msg);
                    }
                })

            },
            _setFieldCount(){
                let data = {
                    Title: this.fieldTitle,
                    FieldName: this.fieldName,
                    CountType: this.CountType,
                    ModelId:this.tableData[this.fieldOnshowIndex].Id,
                };

                if(this.fieldTitle == '' || this.fieldName == ''){
                    this.$message.error('资料请填写完整')
                    return false;
                }
                this.$axios.post(5836166,data,res => {
                    if(res.data.code===1){
                        this._fieldOnshow(this.fieldOnshowIndex);
                        this.fieldChildrenDrawerCountClose();
                        this.$message.success(res.data.msg);
                    }else{
                        this.$message.error(res.data.msg)
                    }
                });
            },
            _setField(){
                let data = {
                    Id: this.FieldId,
                    UserId: user.uid,
                    Title: this.fieldTitle,
                    FieldName: this.fieldValue,
                    FieldType: this.fieldType,
                    FieldDataType: this.fieldType,
                    FieldDataLength: 255,
                    FieldValue: this.defaultValue.length>0 ? JSON.stringify(this.defaultValue):'',
                    Sort: 1,
                    IsSystem: 0,
                    IsSearch: this.isSearch,
                    hintText: this.hintText ? this.hintText : '请选择',
                    ModelId: this.tableData[this.fieldOnshowIndex].Id,
                    Status: 1,
                    Sort:this.Sort,
                    IsRequire:this.IsRequire,
                    IsHide:this.IsHide,
                    IsDisabled:this.IsDisabled,
                    UserType:this.UserType,
                    UserNum:this.UserNum,
                    ListStatus:this.ListStatus,
                    defaultTreeName:this.defaultTreeName.length>0 ? JSON.stringify(this.defaultTreeName):'',
                    defaultTreeId:this.defaultTreeId.length>0 ? JSON.stringify(this.defaultTreeId):'',
                    Only:this.Only,
                };
                if(this.fieldTitle == '' || this.fieldValue == '' || this.fieldType == ''){
                    this.$message.error('资料请填写完整')
                    return false;
                }
                this.$axios.post(5836166,data,res => {
                    if(res.data.code===1){
                        this._fieldOnshow(this.fieldOnshowIndex);
                        this.fieldChildrenDrawerClose();
                        this.$message.success(res.data.msg);
                    }else{
                        this.$message.error(res.data.msg)
                    }
                });
            },
            onChangeFieldType(item){
                if(typeof(item) != "undefined"){
                    this.defaultTreeId = [];
                    this.defaultTreeName = [];
                }
                let self = this;

                if(this.fieldType=='category'){
                    this.$axios.get(1096,{
                        UserId: self.userinfo.uid
                    },res=>{
                        if(res.data.code==1){
                            self.categoryTreeData = res.data.data;
                        }
                    })
                }else if(this.fieldType=='filesource'){
                    this.$axios.get(1097,{
                        UserId: self.userinfo.uid
                    },res=>{
                        if(res.data.code==1){
                            self.fileTreeData = res.data.data;
                        }else{
                            this.$message.error(res.data.msg);
                        }
                    })
                }
            },
            changeTree(value,label){
                this.defaultTreeId = value;
                this.defaultTreeName = label;
            },

            handleClose(removedTag) {
                const tags = this.defaultValue.filter(tag => tag !== removedTag);
                this.defaultValue = tags;
            },

            showInput() {
                this.inputVisible = true;
                this.$nextTick(function() {
                    this.$refs.input.focus();
                });
            },

            handleInputChange(e) {
                this.inputValue = e.target.value;
            },

            handleInputConfirm() {
                const inputValue = this.inputValue;
                let defaultValue = this.defaultValue;
                if (inputValue && defaultValue.indexOf(inputValue) === -1) {
                    defaultValue = [...defaultValue, inputValue];
                }
                Object.assign(this, {
                    defaultValue,
                    inputVisible: false,
                    inputValue: '',
                });
            },

            
            fieldChildrenDrawerClose(){
                this.fieldChildrenDrawer = false;
                this._clearFieldTableData();
            },
            fieldChildrenDrawerCountClose(){
                this.fieldChildrenDrawerCount = false;
                this._clearFieldTableDataCount();
            },
            _fieldOnshow(index){
                this.$axios.post(5836165,{ModelId:this.tableData[index].Id,	UserId: user.uid,Keyword:''},res => {
                    if(res.data.code===1){
                        this.fieldTableData = res.data.data;
                        this.fieldTableDataCount = res.data.countField;
                    }
                });
                this.fieldOnshowIndex = index;
                this.fieldVisible = true;
            },
            _pageList(index){
                this.$router.push({path: '/pageList', query:{moudeid:this.tableData[index].Id,title:this.tableData[index].Name}})

            },
            fieldOnClose(){
                this.fieldTableData = [];
                this.fieldVisible = false;
                this.fieldOnshowIndex = -1;
            },
            _copy(){
                let self = this;
                let text = 'https://saas.sikegroup.com/pageList?moudeid='+this.tableData[this.setItem].Id;
                this.$copyText(text).then(function (e) {
                    self.$message.success('复制成功');
                }, function (e) {
                    self.$message.error('复制失败');
                })

            },
            _setItem(i){
                let self = this;
                this.spinning = true;
                this.$axios.get(1092,{
                    ModelId:'163be40579945dd97d7e9d07a3637639',
                    PageSize:10000,
                },res=>{
                    self.spinning = false;
                    if(res.data.code ==1){
                        self.tableModeList =  res.data.data;
                    }
                })
                if(i=='add'){
                    this.spinning = true;
                    this.$axios.get(5836164, {
                        Keyword:'',
                        PageSize:100000,
                        Page:0,
                    },res => {
                        self.spinning = false;
                        if(res.data.code===1){
                            self.modelList = res.data.data;
                            self.visible = true;
                        }else{
                            this.$message.error(res.data.msg);
                        }
                    });

                }else{
                    this.visible = true;
                    this.setItem = i;
                    this.templateName = this.tableData[i].Name;
                    this.templateId = this.tableData[i].Label;
                    this.tableModeName = this.tableData[i].tableModeName;
                    this.tableModeId   = this.tableData[i].tableModeId;
                    this.disabled = true;//禁止输入
                }

            },
            onClose(){
                this.visible = false;
                this.templateName = '';
                this.templateId = '';
                this.disabled = false;
                this.modelList ='';
                this.CopyModelId ='';
                this.tableModeName = '';
                this.tableModeId   = '';
                this.setItem = -1;
            },
            _delTableRow(i){

                this.spinning = true;
                let self = this;
                this.$axios.post(1094,{
                    ModelId:self.tableData[i].Id,
                    UserId:self.userinfo.uid,
                },res=>{
                    self.spinning = false;
                    if(res.data.code==1){
                        self._loadData();
                        this.$message.success(res.data.msg);
                    }else{
                        this.$message.error(res.data.msg);
                    }
                    console.log(res);
                })
                
            }
        }
    }
</script>

<style scoped lang="less">
    .pageAdmin{
        min-height: 100%;
        padding: 15px;
        background: #fff;
        border-radius: 4px;
        .header{
            margin-top: 10px;
            .condition{
                display: inline-block;
                margin-right: 20px;
                margin-bottom: 10px;
                span{
                    margin-right: 10px;
                }
            }
        }
        .container{
            margin-top: 25px;
            .icon-btn{
                font-size: 20px;
                margin-right: 8px;
                cursor: pointer;
            }
            .state-icon{
                display: inline-block;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                vertical-align: middle;
                background: rgb(255, 127, 56);
                margin-right: 4px;
            }
        }
    }
    .alert-footer{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        border-top: 1px solid #e9e9e9;
        padding: 10px 16px;
        background: #fff;
        button{
            width: 120px;
        }
    }
    .row{
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        .label{
            display: inline-block;
            width: 90px;
            white-space: nowrap;
            span{
                font-size: 16px;
                color: #FF6600
            }
        }
    }
   
</style>